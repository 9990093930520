<template>
  <div>
    <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars" />
    <leyenda-modal
      :areas="areasLegend"
      :modal.sync="legendModal"
    />
    <div>
      <div class="yards-body">
        <div class="wrapper d-flex align-items-stretch">
          <nav id="sidebar-yards">
            <div class="img bg-wrap text-center py-4"
              :style="'background-image: url(' +imageSrc(yardInfo.YardProfileRoute)+ ')'">
            </div>

            <div class="p-3">
              <ul class="list-unstyled components">
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="10">
                        <h5 class="text-white mt-0">
                          {{ yardInfo.YardName }}
                        </h5>
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{$t('label.alias')}}
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.YardAlias }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        AREA
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ formatNumber(yardInfo.SquareMeters) }}&nbsp;M2
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{$t('label.capStatic')}}
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.StaticCapacity }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ $t('label.heights')}}
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.OperationalCapacity }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ $t('label.nroAccess') }}
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.AccesNumber }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ $t('label.yardType') }}
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.YardType }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                       TEUS
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.Teus }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ $t('label.yardGroup') }}
                      </CCol>
                      <CCol sm="6" class="d-flex align-items-center">
                        {{ yardInfo.Alfa }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
              </ul>

              <div v-if="!isCoverWareHouse" class="collapse-areas mt-2" @click="collapseBlocks = !collapseBlocks">
                <span>{{ $t('label.blocks') }}</span>
                <CIcon
                  name="cil-chevron-bottom-alt"
                  class="arrow-icon"
                  :class="{
                    'rotate': collapseBlocks
                  }"
                />
              </div>
              <CCollapse v-if="!isCoverWareHouse" :show="collapseBlocks">
                <div class="bloques">
                  <div
                    v-for="block in blocks"
                    :key="block.YardBlockId"
                    class="grupo"
                  >
                    <div class="block-info">
                      <div class="block-label">
                        {{ `${block.BlockAlias} (${block.BayQuantity})` }}
                      </div>
                      <div class="rotate-icon auto-left"
                        @dragstart="startDrag($event, block, 270)"
                        @dragend="endDrag($event, block, block.YardBlockId)"

                        draggable
                      >
                        <CIcon class="icon-rotation" src="/img/yards/270-GRADOS.svg"/>
                      </div>
                      <div class="rotate-icon"
                        @dragstart="startDrag($event, block, 180)"
                        @dragend="endDrag($event, block, block.YardBlockId)"

                        draggable
                      >
                        <CIcon class="icon-rotation" src="/img/yards/180-GRADOS.svg"/>
                      </div>
                      <div class="rotate-icon"
                        @dragstart="startDrag($event, block, 90)"
                        @dragend="endDrag($event, block, block.YardBlockId)"

                        draggable
                      >
                        <CIcon class="icon-rotation" src="/img/yards/90-GRADOS.svg"/>
                      </div>
                      <div class="rotate-icon"
                        @dragstart="startDrag($event, block, 0)"
                        @dragend="endDrag($event, block, block.YardBlockId)"

                        draggable
                      >
                        <CIcon class="icon-rotation" src="/img/yards/0-GRADOS.svg"/>
                      </div>
                    </div>
                  </div>
                </div>
              </CCollapse>

              <div v-if="isCoverWareHouse ? edit : true" class="other-options mt-4">
                <CButton
                  v-if="edit && yardConfigSelected && yardConfigSelected.YardConfigStatusId != '19902EC7-5EDD-ED11-A88A-000D3A0DEDC7'"
                  block
                  size="sm"
                  color="secondary"
                  class="d-flex align-items-center justify-content-center"
                  @click="toggleApprovalReverse(false)"
                >
                  <CIcon class="setting-options" src="/img/yards/APROBAR-CONFIG-ICON.svg"/>
                  <span class="ml-1">{{$t('label.approveSetting')}}</span>
                </CButton>
                <CButton
                  v-if="edit && yardConfigSelected && yardConfigSelected.YardConfigStatusId == '19902EC7-5EDD-ED11-A88A-000D3A0DEDC7'"
                  block
                  size="sm"
                  color="secondary"
                  class="d-flex align-items-center justify-content-center"
                  @click="toggleApprovalReverse(true)"
                >
                  <CIcon class="setting-options" src="/img/yards/REVERSAR-ICON.svg"/>
                  <span class="ml-1">{{$t('label.reverseSetting')}}</span>
                </CButton>
                <CButton
                  v-if="!isCoverWareHouse"
                  block
                  size="sm"
                  color="secondary"
                  class="d-flex align-items-center justify-content-center"
                  @click="legendModal = true"
                >
                  <div>
                    <CIcon name="cil-eye" />
                    <span class="ml-1">{{$t('label.legend')}}</span>
                  </div>
                </CButton>
                <CButton
                  block
                  size="sm"
                  color="secondary"
                  class="d-flex align-items-center justify-content-center"
                  @click.stop="startPrint"
                  v-if="edit"
                >
                  <CIcon name="cil-print"/>
                  <span class="ml-1">{{$t('label.print')}}</span>
                </CButton>

                <CButton
                  block
                  size="sm"
                  color="secondary"
                  class="d-flex align-items-center justify-content-center"
                  @click.stop="refreshAreaBlockData"
                  v-if="edit"
                >
                  <CIcon name="cil-reload"/>
                  <span class="ml-1">{{$t('label.refresh')}}</span>
                </CButton>
              </div>

              <div class="options mt-3">
                <CButton
                  size="sm"
                  color="add"
                  shape="square"
                  :disabled="submitState"
                  class="ml-2"
                  v-if="!edit"
                  @click="startSubmit"
                >
                  <div v-if="!submitState">
                    <CIcon name="checkAlt" /><span class="ml-1">{{
                      $t("button.accept")
                    }}</span>
                  </div>
                  <div v-if="submitState">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="ml-1">{{ $t("button.accept") }}</span>
                  </div>
                </CButton>
                <CButton
                  size="sm"
                  color="add"
                  shape="square"
                  :disabled="submitStateEdit"
                  class="ml-2"
                  v-if="edit"
                  @click="startEdit"
                >
                  <div v-if="!submitStateEdit">
                    <CIcon name="checkAlt" /><span class="ml-1">{{
                      $t("button.accept")
                    }}</span>
                  </div>
                  <div v-if="submitStateEdit">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="ml-1">{{ $t("button.accept") }}</span>
                  </div>
                </CButton>
                <CButton
                  size="sm"
                  color="wipe"
                  shape="square"
                  :disabled="submitState"
                  class="mr-2"
                  @click="closeCollapse()"
                >
                  <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
                </CButton>
              </div>
            </div>
          </nav>


          <!-- Page Content  -->
          <div id="content">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <div class="container-fluid">

                <button type="button" id="sidebarCollapseVisita" class="btn bg-none text-withe"
                  @click="checkSideBarToggle()">
                  <CIcon class="text-white" :name="checkIconToggle" />
                  <span class="sr-only">{{ checkTextToggle }}</span>
                </button>
                <div class="d-flex align-items-center">
                  <CIcon class="text-white mr-1" name="cil-globe-alt" />
                  <span class="text-white">{{ $t("agridTable.settings") }}</span>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                    <li class="nav-item active d-flex align-items-center justify-content-end" style="margin-right: 10px;" v-if="edit">
                        <a>{{$t('label.status')}}</a>
                    </li>
                    <li class="nav-item d-flex justify-content-center align-items-center" style="margin-right: 10px;" v-if="edit">
                        <input disabled type="text" class="form-control" :value="getConfigStatus" placeholder="">
                    </li>
                    <li @click="closeCollapse()"
                      class="nav-item d-flex align-items-center justify-content-end text-white mr-2">
                      <CIcon name="x" />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <div class="container-fluid contenido-especial">
              <CRow>
                <CCol sm="12">
                  <div class="areas-collapse mb-5">

                    <div class="title-area">
                      <span class="ml-4">AREAS</span>
                    </div>
                    <CRow>
                      <CCol xs="4" sm="4" md="3" lg="3" xl="3" v-for="area in areasList" v-if="getZones(area).length > 0">
                        <div class="navbar-area">
                          <div
                            class="specific-area"
                            :class="{
                                'underline': collapseBottomArea === area.TpYardAreaId? true: null,
                            }"
                            @click="setCollapseArea(area)"
                            
                          >
                            <div class="color-area" :style="{ backgroundColor: area.TpYardAreaColor}"></div>
                            <span><div style="padding-right:5px;">{{ area[`TpYardAreaName${lang}`] }}</div></span>
                            <CIcon
                              name="cil-chevron-bottom-alt"
                              class="arrow-icon"
                              :class="{
                                'rotate': collapseBottomArea === area.TpYardAreaId ? true: null,
                              }"
                            />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    
                    <CCollapse :show="collapseBottomArea !== '' ? true:false">
                      <div class="collapses" >
                        <div
                          v-for="zone in collapseBottomData"
                          :key="zone.YardAreaId"
                          class="area"
                          draggable
                          @dragstart="startDrag($event, zone)"
                          @dragend="endDrag($event, zone, zone.YardAreaId)"
                        >
                          <div class="item">
                            <svg v-if="!isCoverWareHouse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.28 40.74">
                              <path d="M41.64 16.27c0 9.78-7.43 12.72-11.45 18.91a18.68 18.68 0 0 0-2.69 7.69 20.52 20.52 0 0 0-2.92-7.69C20.26 29 13.36 26.26 13.36 16.27a14.14 14.14 0 1 1 28.28 0Z" data-name="Capa 2" :style="{fill: zone.TpYardAreaColor}" transform="translate(-13.36 -2.13)"/>
                            </svg>
                            <div v-else class="area-module" style="width: 90%; height: 25px"></div>
                            <CIcon class="icon" :src="iconSrc(zone.TpYardAreaIconRoute)" size="sm" v-if="zone.TpYardAreaIconRoute"/>
                          </div>
                          <div class="item-label col-sm-6 px-0">
                            {{ zone.YardAreaName }}
                          </div>
                        </div>
                      </div>
                    </CCollapse>
                  </div>
                  <YardSeventeen
                    v-if="getCurrentYard == 'C6E16D79-982D-4BFC-86B0-FF10251A5635' && collapse"
                    ref="gridlayout"
                    :layout="layoutZone"
                    config
                    @submited="handleSubmit"
                    @edited="handleEdit"
                    @print="printPdf"
                    @update-layout="updateLayout"
                    @remove="removeAreaBlock"
                  />
                  <YardTwelve
                    v-if="getCurrentYard == '35E02EE5-8478-4AA2-8EE0-41817EF91B4D' && collapse"
                    ref="gridlayout"
                    :layout="layoutZone"
                    config
                    @submited="handleSubmit"
                    @edited="handleEdit"
                    @print="printPdf"
                    @update-layout="updateLayout"
                    @remove="removeAreaBlock"
                  />
                  <YardFour
                    v-if="getCurrentYard == 'C62F4B69-011F-ED11-A87E-000D3A0DEDC7' && collapse"
                    ref="gridlayout"
                    :layout="layoutZone"
                    config
                    @submited="handleSubmit"
                    @edited="handleEdit"
                    @print="printPdf"
                    @update-layout="updateLayout"
                    @remove="removeAreaBlock"
                  />
                  <YardThirteen
                    v-if="getCurrentYard == 'F6513E84-8D61-4D20-8CCD-429AAEC6DDF4' && collapse"
                    ref="gridlayout"
                    :layout="layoutZone"
                    config
                    @submited="handleSubmit"
                    @edited="handleEdit"
                    @print="printPdf"
                    @update-layout="updateLayout"
                    @remove="removeAreaBlock"
                  />
                  <CoverWarehouse
                    v-if="isCoverWareHouse && collapse"
                    ref="gridlayout"
                    :layout="layoutZone"
                    config
                    @submited="handleSubmit"
                    @edited="handleEdit"
                    @print="printPdf"
                    @update-layout="updateLayout"
                    @remove="removeAreaBlock"
                  />
                  <GenericYard
                    v-if="yardSelected.FgGenericYard && collapse"
                    ref="gridlayout"
                    :layout="layoutZone"
                    config
                    @submited="handleSubmit"
                    @edited="handleEdit"
                    @print="printPdf"
                    @update-layout="updateLayout"
                    @remove="removeAreaBlock"
                  />
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div>
      <br><br>
    </div>
    <ChangeStatusModal
      :modal.sync="changeModal"
      :reverse="reverse"
      @submited="handleChange()"
    />
    <NewSettingsModal
      :modal.sync="newSettingsModal"
      :yardSelected="yardSelected"
      :layout="layoutSubmit"
      @submited="handleSettingModal()"
      :isEdit="isEdit"
    />
  </div>
</template>

<script>
import ENUM from '@/_store/enum';
import { mapState } from 'vuex';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import YardSeventeen from './yards/yard-seventeen';
import YardTwelve from './yards/yard-twelve';
import YardFour from './yards/yard-four';
import YardThirteen from './yards/yard-thirteen';
import GenericYard from './yards/pa-gen';
import CoverWarehouse from './yards/cover-warehouse.vue';
import YardGridMixin from '@/_mixins/yard-grid';
import YardGrid from './yards/yard-grid';
import LeyendaModal from "./yards/legend-modal";
import ChangeStatusModal from './yards/change-status-modal';
import NewSettingsModal from './yards/new-settting-modal';

import ldb from 'localdata';

//data
function data() {
  return {
    new_pos: {
      x: 0,
      y: 0,
    },
    mouseXY: {
      x: 0,
      y: 0,
    },
    srcError: '/img/errorimage.jpg',
    yardInfo: {
      YardName: '',
      YardAlias: '',
      SquareMeters: '',
      Teus: '',
      StaticCapacity: '',
      OperationalCapacity: '',
      AccesNumber: '',
      YardType: '',
      YardGpoName: '',
      YardProfileRoute: '',
    },
    collapsed: false,
    loadingOverlay: false,
    settingData: null,
    areas: [],
    areasList: [],
    blocks: [],
    collapseAreas: false,
    collapseBlocks: false,
    layoutZone: [],
    areasLegend: [],
    legendModal: false,
    changeModal: false,
    layoutSubmit: [],
    newSettingsModal: false,
    reverse: false,
    isEdit: false,
    // Areas
    collapseBottomArea: '',
    collapseBottomData: [],
    deleteLayout: [],
    lang: (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1),
  }
}

//created 
async function created() {
  await this.getListAreaType();

  if(!this.edit)
    this.getAreaBlock();
  else {
    this.setEditData();
  }
}

//mounted
function mounted() {
  document.getElementById("sidebarCollapseVisita").onclick = function () {
    document.getElementById("sidebar-yards").classList.toggle('active');
  }
  document.addEventListener("dragover", (e) => {
    this.mouseXY.x = e.clientX;
    this.mouseXY.y = e.clientY;
  }, false);
}

//Methods
function getListAreaType() {
  this.$store.state.yardsetting.apiState = ENUM.LOADING;
  this.$http
    .get('TpYardArea-list',{ Filter: 'ACTIVO'})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.areasList = listado;
      }
    })        
    .finally(() => {
      this.$store.state.yardsetting.apiState = ENUM.INIT;
    });
}
function formaterNormalDate(date) {
  return date ? DateFormater.formatOnlyDateWithSlash(date) : 'N/A';
}
function formatNumber(num) {
  if(!num) return '';
  return NumberFormater.formatNumber(num, 2);
}
function handleTab(tab) {
  this.$store.state.yardsetting.tabIndex = tab;

}
function imageSrc(Src) {
  if (Src === '' || !Src) {
    return this.srcError;
  }
  else {
    if(Src.startsWith("http"))
      return Src;
    else 
      return `${this.$store.getters["connection/getBase"]}${Src}`;
  }
}
function iconSrc(src) {
  return `${this.$store.getters["connection/getBase"]}${src}`;
}
function closeCollapse() {
  this.$store.state.yardsetting.tabIndex = 0;
  this.$store.state.yardsetting.collapse = false;
  this.$store.state.yardsetting.configCollapse = true;
  this.$store.state.yardsetting.yardConfigSelected = null;
}
function checkSideBarToggle() {
  this.collapsed = !this.collapsed;
}
function getAreaBlock() {
  this.$store.state.yardsetting.apiState = ENUM.LOADING;
  this.$http
    .get("YardAreaBlock-list-by-YardId", {
      YardId: this.yardSelected.YardId,
    })
    .then((response) => {
      this.settingData = {...response.data.data[0]};
      this.setData(this.settingData);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    }).then(() => {
      this.$store.state.yardsetting.apiState = ENUM.INIT;
    });
}
function setData(setting) {
  this.yardInfo.YardName = this.yardSelected.YardName;
  this.yardInfo.YardAlias = this.yardSelected.YardAlias;
  this.yardInfo.SquareMeters =  this.yardSelected.SquareMeters;
  this.yardInfo.Teus = this.yardSelected.Teus;
  this.yardInfo.StaticCapacity = this.yardSelected.StaticCapacity;
  this.yardInfo.OperationalCapacity = this.yardSelected.OperationalCapacity;
  this.yardInfo.AccesNumber = this.yardSelected.AccesNumber;
  this.yardInfo.YardType = this.yardSelected.YardType;
  this.yardInfo.YardGpoName = this.yardSelected.Alfa;
  this.yardInfo.YardProfileRoute = this.yardSelected.YardProfileRoute;

  this.areas = setting.AreaJson;
  this.blocks = setting.BlockJson;
}
function setEditData() {
  if(!this.yardConfigSelected && !this.yardSelected) return;

  this.yardInfo.YardName = this.yardSelected.YardName;
  this.yardInfo.YardAlias = this.yardSelected.YardAlias;
  this.yardInfo.SquareMeters = this.yardSelected.SquareMeters ? this.yardSelected.SquareMeters : '';
  this.yardInfo.Teus = this.yardSelected.Teus;
  this.yardInfo.StaticCapacity = this.yardSelected.StaticCapacity ? this.yardSelected.StaticCapacity : '';
  this.yardInfo.OperationalCapacity = this.yardSelected.OperationalCapacity ? this.yardSelected.OperationalCapacity : '';
  this.yardInfo.AccesNumber = this.yardSelected.AccesNumber ? this.yardSelected.AccesNumber : '';
  this.yardInfo.YardType = this.yardSelected.YardType;
  this.yardInfo.YardGpoName = this.yardSelected.Alfa;
  this.yardInfo.YardProfileRoute = this.yardSelected.YardProfileRoute;

  let areasAux = JSON.parse(JSON.stringify(this.yardConfigSelected.YardConfigDetailJson[0].AreaJson));
  this.areas = areasAux.filter((item) => item.YardConfigDetailId == null);

  let blocksAux = JSON.parse(JSON.stringify(this.yardConfigSelected.YardConfigDetailJson[0].BlockJson));
  this.blocks = blocksAux.filter((item) => item.YardConfigDetailId == null);

  this.layoutZone = [
    ...areasAux.filter((item) => item.YardConfigDetailId != null),
    ...blocksAux.filter((item) => item.YardConfigDetailId != null)
  ];
}
function refreshAreaBlockData() {
  this.$store.state.yardsetting.apiState = ENUM.LOADING;

  let yardConfigId = this.yardConfigSelected ? this.yardConfigSelected.YardConfigId : '';

  this.$http.ejecutar("GET", "YardConfig-by-Id", {
    YardConfigId: yardConfigId,
  }).then(response => {
    let info = response.data.data[0];

    this.areas = info.YardConfigDetailJson[0].AreaJson.filter((item) => item.YardConfigDetailId == null);
    this.blocks = info.YardConfigDetailJson[0].BlockJson.filter((item) => item.YardConfigDetailId == null);

    this.layoutZone = [
      ...info.YardConfigDetailJson[0].AreaJson.filter((item) => item.YardConfigDetailId != null),
      ...info.YardConfigDetailJson[0].BlockJson.filter((item) => item.YardConfigDetailId != null)
    ];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.$store.state.yardsetting.apiState = ENUM.INIT;
  });
}

function addArea(item, area, tpa) {
  this.layoutZone = this.layoutZone.filter(arr => arr.i != item.YardAreaId)
  this.layoutZone.push({
    ...item,
    x: 0,
    y: 0,
    w: 2,
    h: 3,
    a: 0,
    resizable: true,
    elementType: 'area',
    i: item.YardAreaId,
    Location: area,
    tpa: Number.parseInt(tpa),
  });
  /*let index = this.layoutZone.findIndex(layout => layout.i === item.YardAreaId);
  this.$refs.gridlayout.$refs.layoutOne.dragEvent('dragend', item.YardAreaId, this.new_pos.x, this.new_pos.y, this.layoutZone[index].h, this.layoutZone[index].w);
  this.$refs.gridlayout.$refs.layoutOne.$children[this.layoutZone.length].$refs.item.style.display="block";*/

  let found = this.areas.findIndex((area) => area.YardAreaId == item.YardAreaId);
  if(found != -1){
    this.areas.splice(found, 1);
    let foundArea  = this.areasLegend.findIndex((dat) => dat.TpYardAreaId == item.TpYardAreaId);
    if(foundArea == -1) {
      this.areasLegend.push({
        elementType: 'area',
        ...item,
        areaName: item[`TpYardAreaName${this.lang}`],
      });
      if(this.edit && item.YardConfigDetailId) {
        let foundRemove = this.deleteLayout.findIndex((area) => area.YardAreaId == item.YardAreaId);
        if(foundRemove != -1) {
          this.deleteLayout.splice(foundRemove, 1);
        }
      }
    }
  }
}
function addBlock(item, area, tpa, current) {
  this.layoutZone = this.layoutZone.filter(arr => arr.i != item.YardBlockId)
  this.layoutZone.push({
    ...item,
    x: 0,
    y: 0,
    w: tpa == '0' || tpa == '180' 
      ? 8 
      : 2, //tpa == '0' || tpa == '180' ? 15 : 3,
    h: tpa == '0' || tpa == '180' 
      ? 3
      : 8, //tpa == '0' || tpa == '180' ? 3 : 15,
    a: 0,
    resizable: true,
    elementType: 'block',
    i: item.YardBlockId,
    Location: area,
    tpa: Number.parseInt(tpa),
  });
  /*let index = this.layoutZone.findIndex(layout => layout.i === item.YardBlockId);
  this.$refs.gridlayout.$refs.layoutOne.dragEvent('dragend', item.YardBlockId, this.new_pos.x, this.new_pos.y, this.layoutZone[index].h, this.layoutZone[index].w);
  this.$refs.gridlayout.$refs.layoutOne.$children[this.layoutZone.length].$refs.item.style.display="block";*/

  let found = this.blocks.findIndex((slot) => slot.YardBlockId == item.YardBlockId);
  if(found != -1){
    this.blocks.splice(found, 1);
    if(this.edit && item.YardConfigDetailId) {
      let foundRemove = this.deleteLayout.findIndex((area) => area.YardBlockId == item.YardBlockId);
      if(foundRemove != -1) {
        this.deleteLayout.splice(foundRemove, 1);
      }
    }
  }
}
function displacementPos(arr) {
  if(arr.length == 0) return 1;
  let xPos = 1, found = -1;

  do {
    found = arr.findIndex((item) => item.x == xPos && item.y == 1);
    if(found != -1)
      xPos = xPos+2;
  } while (found != -1);

  return xPos;
}
function updateLayout(id, divId, tpa, current) {
  let foundArea = this.areas.find((dat) => dat.YardAreaId == id);
  if(foundArea){
    this.addArea(foundArea, divId, tpa);
  }

  let foundBlock = this.blocks.find((dat) => dat.YardBlockId == id);
  if(foundBlock){
    this.addBlock(foundBlock, divId, tpa, current);
  }
}
function startDrag(evt, item, tpa=0) {
  evt.dataTransfer.dropEffect = 'move'
  evt.dataTransfer.effectAllowed = 'move'
  if(item.YardAreaId){
    evt.dataTransfer.setData('area', item.YardAreaId);
    evt.dataTransfer.setData('type', 'area');
    evt.dataTransfer.setData('atype', tpa);
  }
  if(item.YardBlockId) { 
    evt.dataTransfer.setData('area', item.YardBlockId);
    evt.dataTransfer.setData('type', 'block');
    evt.dataTransfer.setData('atype', tpa);
  }
  
  this.$store.state.yardsetting.dragging = true;
}
// function drag(id, item, tpa, area) {
//   let parentRect = document.getElementById('areaOne').getBoundingClientRect();
//   if (((this.mouseXY.x > parentRect.left) && (this.mouseXY.x < parentRect.right)) && ((this.mouseXY.y > parentRect.top) && (this.mouseXY.y < parentRect.bottom))) {
//     let index = this.layoutZone.findIndex(layout => layout.i === id);
//     if (index === -1){
//       this.layoutZone.push({
//         ...item,
//         x: 0,
//         y: 0,
//         w: tpa == '0' || tpa == '180' 
//           ? 8 
//           : 2, //tpa == '0' || tpa == '180' ? 15 : 3,
//         h: tpa == '0' || tpa == '180' 
//           ? 3
//           : 8, //tpa == '0' || tpa == '180' ? 3 : 15,
//         a: 0,
//         resizable: true,
//         elementType: area,
//         i: id,
//         Location: 'AREA_1',
//         tpa: Number.parseInt(tpa),
//       });
//     }else{
//       this.$refs.gridlayout.$refs.layoutOne.$children[this.layoutZone.length].$refs.item.style.display = "none";
//       this.$refs.gridlayout.$refs.layoutOne.$children[index].dragging = {"top": this.mouseXY.y - parentRect.top, "left": this.mouseXY.x - parentRect.left};
//       this.new_pos = this.$refs.gridlayout.$refs.layoutOne.$children[index].calcXY(this.mouseXY.y - parentRect.top, this.mouseXY.x - parentRect.left);
//       this.$refs.gridlayout.$refs.layoutOne.dragEvent('dragstart', id, this.new_pos.x, this.new_pos.y, this.layoutZone[index].h, this.layoutZone[index].w);
//     }
//   }else{
//     let index = this.layoutZone.findIndex(layout => layout.i === id);
//     if (index != -1) {
//       this.$refs.gridlayout.$refs.layoutOne.dragEvent('dragend', id, this.new_pos.x, this.new_pos.y, this.layoutZone[index].h, this.layoutZone[index].w);
//       this.layoutZone = this.layoutZone.filter(layout => layout.i != id);
//     }
//   }
// }
function endDrag(evt, item, id) {
  /*this.layoutZone = this.layoutZone.filter(arr => {
    if (((arr.y*14.85)+(arr.h*14.84)<=416) && arr.i != id) {
      return true
    }else{
      this.areas.push(item);
      if(this.edit && item.YardConfigDetailId){
        this.deleteLayout.push({
          ...item,
          Status: 0,
        });
      }
      return false;
    }
  })*/
  this.$store.state.yardsetting.dragging = false;
  this.setCollapseArea(item, true);
}
function startPrint(){
  this.$store.state.yardsetting.printing = true;
}
function printPdf(layout) {
  let pageInfo = {
    layoutList: layout,
    yardInfo: this.yardSelected,
    areas: this.areasLegend,
  };

  ldb.set('pageInfoYard', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfYard'});
  window.open(routeData.href, '_blank');
  this.$store.state.yardsetting.printing = false;
}

function setCollapseArea(area, fg = false)
{
 
  let zone = this.getZones(area);

  this.collapseBottomArea = this.collapseBottomArea == area.TpYardAreaId ? (fg && zone.length > 0  ? area.TpYardAreaId : '') : area.TpYardAreaId;  

  this.collapseBottomData = this.collapseBottomArea != '' ? this.getZones(area) : [];
}
function startSubmit(){
  this.$store.state.yardsetting.isSubmit = true;
}
function handleSubmit(layout) {
  this.layoutSubmit = layout;
  this.$store.state.yardsetting.isSubmit = false;
  this.newSettingsModal = true;
  
}
function startEdit(){
  this.isEdit = true;
  this.$store.state.yardsetting.isSubmitEdit = true;
}
function handleEdit(layout) {
  this.layoutSubmit = layout;
  this.$store.state.yardsetting.isSubmitEdit = false;
  this.newSettingsModal = true;
}
function toggleApprovalReverse(stat) {
  this.reverse = stat;
  this.changeModal = true;
}
function handleChange() {
  this.reverse = false;
  this.$emit("action", 'submit');
  this.$store.state.yardsetting.isSubmit = false;
}
function handleSettingModal() {
  if(!this.isEdit){
    this.$emit("action", 'submit');
    this.$store.state.yardsetting.isSubmit = false;
  }else{
    this.$emit("action", 'edit');
    this.$store.state.yardsetting.isSubmitEdit = false;
  }
  
}
function removeAreaBlock(item) {
  if(item.YardAreaId){
    let found = this.layoutZone.findIndex((it) => it.YardAreaId == item.YardAreaId);
    if(found != -1) {
      this.layoutZone.splice(found, 1);
      this.areas.push(item);
      if (this.collapseBottomArea) {
        this.setCollapseArea(item, true)
      }
      if(this.edit && item.YardConfigDetailId){
        this.deleteLayout.push({
          ...item,
          Status: 0,
        });
      }
    }
  } else if(item.YardBlockId){
    let found = this.layoutZone.findIndex((it) => it.YardBlockId == item.YardBlockId);
    if(found != -1) {
      this.layoutZone.splice(found, 1);
      this.blocks.push(item);
      if(this.edit && item.YardConfigDetailId){
        this.deleteLayout.push({
          ...item,
          Status: 0,
        });
      }
    }
  }
}
//computed
function apiStateLoading() {
  let carga = false;
  if (this.apiState === ENUM.LOADING) {
    carga = !carga;
  }
  return carga;
}

function checkIconToggle() {
  if (this.collapsed) {
    return "cil-chevron-right-alt";
  } else {
    return "cil-chevron-left-alt";
  }
}

function checkTextToggle() {
  if (this.collapsed) {
    return this.$t("label.close");
  } else {
    return this.$t("label.watch");
  }
}

function getZones(area) {
  return this.areas.filter((item) => item.TpYardAreaId == area.TpYardAreaId);
}

function getCurrentYard() {
  if(!this.yardSelected) return "";

  return this.yardSelected.YardId;
}

function getConfigStatus() {
  if(!this.yardConfigSelected) return '';
  return this.yardConfigSelected[`YardConfigStatusDs${this.lang}`]; 
}

function isCoverWareHouse() {
  return this.yardSelected?.YardTpId == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE
}

export default {
  name: 'menu-yard-setting',
  mixins: [YardGridMixin],
  components: {
    CustomTabs,
    CustomTab,
    YardSeventeen,
    YardTwelve,
    YardFour,
    YardThirteen,
    GenericYard,
    CoverWarehouse,
    YardGrid,
    LeyendaModal,
    ChangeStatusModal,
    NewSettingsModal
  },
  props:{
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data,
  created,
  mounted: mounted,
  methods: {
    formaterNormalDate,
    handleTab,
    imageSrc,
    closeCollapse,
    checkSideBarToggle,
    getAreaBlock,
    setData,
    iconSrc,
    addArea,
    addBlock,
    printPdf,
    setCollapseArea,
    startSubmit,
    handleSubmit,
    handleEdit,
    setEditData,
    startEdit,
    toggleApprovalReverse,
    handleChange,
    handleSettingModal,
    startPrint,
    startDrag,
    //drag,
    updateLayout,
    endDrag,
    removeAreaBlock,
    formatNumber,
    refreshAreaBlockData,
    displacementPos,
    getListAreaType,
    getZones,
  },
  computed: {
    apiStateLoading,
    checkTextToggle,
    checkIconToggle,
    getCurrentYard,
    getConfigStatus,
    isCoverWareHouse,
    ...mapState({
      branch: state => state.auth.branch,
      apiState: state => state.yardsetting.apiState,
      collapse: state => state.yardsetting.collapse,
      tabIndex: state => state.yardsetting.tabIndex,
      yardSelected: state => state.yardsetting.yardSelected,
      yardConfigSelected: state => state.yardsetting.yardConfigSelected,
      submitState: state => state.yardsetting.isSubmit,
      submitStateEdit: state => state.yardsetting.isSubmitEdit,
    })
  },
  watch: {

  }
}
</script>

<style lang="scss" >
@import 'css/style.scss';

.dropdowntab>.custom-tab-link {
  padding: 0px;
}

.custom-nav li>.custom-tab-link {
  .dropdown {
    width: 100%;
    padding: 0 20px;

    .dropdown-toggle {
      color: white;
      opacity: 1;
      padding-left: 0;
      padding-top: 0;

    }

    .dropdown-toggle::after {
      margin-left: 0.255em;
      margin-right: 1.0em;
    }
  }
}

.custom-nav li.active>.custom-tab-link {
  .dropdown {
    width: 100%;
    padding: 0 20px;

    .dropdown-toggle {
      color: #000;
      opacity: 1;
    }

    .dropdown-toggle::after {
      margin-left: 0.255em;
      margin-right: 1.0em;
    }
  }
}

.iconTab {
  max-width: 100%;
  height: auto;
  border-radius: 0.25rem !important;
  margin-left: 1.5rem !important;
  margin-top: -0.4rem !important;
}

.separate-list-item {
  border-bottom: 1px solid #fff !important;
}

.test-yard {
  width: 60%;
  height: 200px;
}
.setting-options {
  width: 8%;
}
.rotate-icon {
  width: 13%;
  margin-left: 2px;
  cursor: pointer;
}
.rotate-icon .icon-rotation {
  width: 100%;
}
.auto-left {
  margin-left: auto;
}

.areas-collapse {
  width: 100%;
  // min-height: 100px;
  display: flex;
  flex-direction: column;
  background-color: #ebedef;
  // margin-top: 15px;
  // padding-bottom: 15px;

  .title-area {
    width: 100%;
    color: #5d6164;
    margin-bottom: 10px;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .navbar-area {
    display: flex;

    .specific-area {
      color: #5d6164;
			cursor: pointer;
			padding: 4px 10px;
			border-radius: 3px;
			display: flex;
			align-items: center;

      &.underline {
        //border-bottom: 1px solid #5d6164;
        font-weight: bold;
        background: #d8dbe0;

        .color-area  {
          border: 1px solid #000;
        }      
      }
      .color-area {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-color: #fff;
        // margin-left: auto;
      }

			.arrow-icon {
				margin-left: auto;
				transition: transform 0.15s ease-in;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				
				&.rotate {
				  -webkit-transform: rotate(180deg);
				  -moz-transform: rotate(180deg);
				  -ms-transform: rotate(180deg);
				  -o-transform: rotate(180deg);
				  transform: rotate(180deg);
				}
			  }
		}
  }
  .collapses {
    width: 98%;
    display: flex;
    align-items: center;
    background-color: #ebedef;
    margin: 10px auto;

    position: relative;
    flex-wrap: wrap;

    .area {
      cursor: pointer;
      min-height: 60px;						
      width:	calc(calc(100% / 8));
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 5px;

      .item {
        width: 100%;
        min-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon {
          width: 35%;
          position: absolute;
          top: 14%;
        }
        .icon-office {
          width: 24% !important;
        }
        .icon-work {
          width: 30% !important;
        }
        svg {
          width: 55%;
        }
      }
      .item-label {
        font-size: 10px;
        font-weight: bold;
        color: #5d6164;
      }
    }
  }
}
.tab-pad {
  padding-left: 15px;
}
</style>